import getApiService from '@/commons/services/FrontendJsonService';

export default class PlansService {
  static async fetchData() {
    const apiService = getApiService('plansRoute');
    const response = await apiService.client.get('');

    apiService.checkResponse(response);

    return response;
  }
}
