/**
 * Generates a unique ID
 * @returns string
 */
export function getUniqueId(): string {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  );
}

/**
 * @description
 * Checks html and body for RTL direction
 */
export function isRTL() {
  const htmlTag = document.querySelector('html');
  const bodyTag = document.querySelector('body');
  return (
    htmlTag?.getAttribute('dir') === 'rtl' ||
    bodyTag?.getAttribute('dir') === 'rtl'
  );
}

export function isMobile() {
  const tabletValue = getComputedStyle(document.documentElement)
    .getPropertyValue('--tablet') || '600px';

  const tablet = parseInt(tabletValue);
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  
  return vw < tablet;
}