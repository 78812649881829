import { i18n } from '@/commons/config/i18n';

const findAmountByKey = (prefix: string, suffix: string = '') => {
  let i = 1;
  prefix = prefix.length > 0 ? `${prefix}.` : '';
  suffix = suffix.length > 0 ? `.${suffix}` : '';
  while (i18n.te(`${prefix}${i}${suffix}`)) {
    i++;
  }
  return i - 1;
};

export { findAmountByKey };
