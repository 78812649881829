import Vue from 'vue';
import 'cvo-library';
import { viewResources } from '@/commons/utils/viewResources';
import getI18nConfig from '@/commons/config/i18n';
import { enUS as en, it, pl } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import currencyInstance from '@/commons/modules/currency/currencyInstance';

viewResources.addGeneric('url').setValues(window.CVO.urls);

const carrier = window.CVO.tenantCarrier as string;
const currency = window.CVO.activeCurrency as string;
const language = window.CVO.currentLanguage as string;

currencyInstance.setConfig(carrier, currency, language);

export default function createApp(options: Object) {
  getI18nConfig(language).then((i18n) => {
    setDefaultOptions({ locale: { en, it, pl }[language] });
    new Vue({ ...options, i18n })
  });
}
