import { AutocompleteItem } from "@/commons/models";

export default class Autocomplete {
  suggestions: Array<AutocompleteItem> | null;
  filteredSuggestions: Array<AutocompleteItem> = [];

  constructor(
    suggestions?: Array<AutocompleteItem>,
  ) {
    this.suggestions = suggestions || null;
  }

  public filterSuggestions(query: string): void {
    if(!this.suggestions || !this.suggestions.length) {
      return;
    }

    const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const searchRegex = new RegExp(escapedQuery, "i");
    const filteredItems = this.suggestions.filter((item) => { 
      return (
        item.name.match(searchRegex) ||
        item.description?.match(searchRegex)
      );
    })
    this.filteredSuggestions = filteredItems;
  }

  public setSuggestions(suggestions: Array<AutocompleteItem>): void {
    this.suggestions = suggestions;
  }
}