
import Vue, { VueConstructor } from "vue";
import formMixin from "@/commons/mixins/form/form"
import Events from "@/commons/constants/events";
import { FormLocaleCode } from '@/commons/models';
import { ValidationObserver } from "vee-validate";

const ExtendedVue = Vue as VueConstructor<Vue & InstanceType<typeof formMixin>>;

export default ExtendedVue.extend({
  name: "Form",
  mixins: [ formMixin ],
  created() {
    /**
     * Configure the form validator
     * Note: should set the locale for the error messages
     */
    this.formValidator(this.errorsLocale as FormLocaleCode);
  },
  methods: {
    /**
     * Handle form submit
     */
    onSubmit(): void {
      const data = new FormData(this.$refs.form);
      /**
       * Emits the register data when the user submits the form `RegisterPayload`
       *
       * @event submit
       * @property {RegisterPayload} registePayload
       */
      this.$emit(Events.SUBMIT, Object.fromEntries(data));
    },

    async isValid(): Promise<boolean> {
      const observer = this.$refs.observer as InstanceType<
        typeof ValidationObserver
      >;
      return await observer.validate();
    },

    resetValidation(): void {
      const observer = this.$refs.observer as InstanceType<
        typeof ValidationObserver
      >;

      observer.reset();
    }
  }
});
