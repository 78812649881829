
import Vue from "vue";
import VTooltip from "v-tooltip";

Vue.use(VTooltip, {
  defaultTrigger: "hover focus click",
  defaultTemplate: `
    <div class="Tooltip-message" role="tooltip">
      <div class="tooltip-arrow"></div>
      <div class="tooltip-inner"></div>
    </div>`
});

export default Vue.extend({
  name: "Tooltip",
  props: {
    content: {
      required: true,
      type: String,
      default: ""
    },
    position: {
      required: false,
      type: String,
      default: "top",
      validator: (value: string): boolean => {
        return ["top", "bottom", "left", "right"].includes(value);
      }
    }
  }
});
