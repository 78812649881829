
  import Vue, { PropType } from 'vue';
  import Events from "@/commons/constants/events";
  import { ButtonColor, ButtonSize } from '@/commons/models';

  export default Vue.extend({
    name: 'ActionButton',

    props: {
      iconAfter: {
        type: String,
        required: false,
      },
      iconBefore: {
        type: String,
        required: false,
      },
      color: {
        type: String as PropType<ButtonColor>,
        default: 'primary',
        required: false,
      },
      size: {
        type: String as PropType<ButtonSize>,
        default: 'md',
        required: false,
      },
      disabled: {
        type: Boolean,
        default: false,
        required: false,
      },
      type: {
        type: String,
        default: 'button',
        required: false,
      }
    },

    methods: {
      buttonClicked() {
        this.$emit(Events.CLICK, ...arguments);
      },
    },

    computed: {
      buttonClasses() {
        const classes = [
          `ActionButton--${this.color}`,
          `ActionButton--${this.size}`,
        ];
        if (!this.$slots.default) {
          classes.push('ActionButton--iconOnly');
        }
        return classes;
      }
    },
  });
