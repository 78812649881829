var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"Form"},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({
      handleSubmit,
      dirty,
      pristine,
      valid,
      invalid,
      pending,
      touched,
      untouched,
      passed,
      failed,
      errors,
      reset
    }){return [_c('form',{ref:"form",attrs:{"novalidate":"","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_vm._t("default",null,{"dirty":dirty,"pristine":pristine,"valid":valid,"invalid":invalid,"pending":pending,"touched":touched,"untouched":untouched,"passed":passed,"failed":failed,"errors":errors,"reset":reset})],2)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }