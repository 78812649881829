
  import Tab from '@/commons/components/tabs/Tab.vue';

  export default {
    name: 'Tabs',

    data() {
      return {
        selectedIdx: 0,
        tabs: new Array<typeof Tab>(),
      }
    },

    created() {
      this.tabs = this.$children;
    },

    mounted() {
      this.selectTab(0);
    },

    methods: {
      selectTab(idx: number) {
        this.tabs.forEach((tab: typeof Tab, i: number) => {
          tab.isActive = i === idx;
        });
        this.selectedIdx = idx;
      }
    }
  }
