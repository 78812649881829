/* eslint-disable */

/**
 * TODO: move all the l18n data to external service
 */

const locale = {
  ar: {
    code: "ar",
    messages: {
      alpha: "يجب أن يحتوي الحقل على أحرف أبجدية فقط",
      alpha_num: "يجب أن يحتوي الحقل على أحرف أبجدية رقمية فقط",
      alpha_dash:
        "قد يحتوي الحقل على أحرف أبجدية رقمية بالإضافة إلى شرطات وشرطات سفلية",
      alpha_spaces: "يجب أن يحتوي الحقل على أحرف أبجدية ومسافة فقط",
      between: "يجب أن يكون الحقل بين {min} و{max}",
      confirmed: "تأكيد الحقل غير متطابق",
      credit_card: "رقم بطاقة الائتمان غير صالح",
      digits: "يجب أن يكون الحقل رقميًا ويحتوي بالضبط على {length} أرقام",
      dimensions: "يجب أن يكون الحقل {width} بكسل × {height} بكسل",
      email: "يجب أن يحتوي الحقل على بريد إلكتروني صالح",
      excluded: "لا يحتوي الحقل على قيمة صالحة",
      ext: "لا يحتوي الحقل على ملف صالح",
      image: "يجب أن يحتوي الحقل على صورة",
      integer: "يجب أن يكون الحقل عددًا صحيحًا",
      length: "يجب أن يكون طول الحقل {length}.",
      max_value: "يجب أن يكون الحقل {max} أو أقل",
      max: "لا يجوز أن يكون الحقل أكبر من {length} حرفًا",
      mimes: "يجب أن يحتوي الحقل على نوع ملف صالح",
      min_value: "يجب أن يكون الحقل {min} أو أكثر",
      min: "يجب أن يتكون الحقل من {length} رموز على الأقل",
      numeric: "يجب أن يحتوي الحقل على أرقام فقط",
      oneOf: "لا يحتوي الحقل على قيمة صالحة",
      regex: "تنسيق الحقل غير صالح",
      required_if: "هذا الحقل مطلوب",
      required: "هذا الحقل مطلوب",
      size: "يجب أن يكون حجم الحقل أقل من {size} كيلو بايت",
      double: "يجب أن يكون الحقل رقمًا عشريًا صالحًا",
      strong_password: "1 حرف كبير، 1 حرف صغير، 1 رقم و1 حرف خاص",
      regular_password: "1 حرف كبير و1 حرف صغير و1 رقم",
      alpha_num_space: "يجب أن يحتوي الحقل على رموز أبجدية رقمية ومسافة فقط",
      not_underage: "يجب أن يكون عمر صاحب العضوية {age} عامًا أو أكبر"
    }
  },
  bg: {
    code: "bg",
    messages: {
      alpha: "Полето може да съдържа само буквени символи",
      alpha_num: "Полето може да съдържа само буквено-цифрови символи",
      alpha_dash:
        "Полето може да съдържа буквено-цифрови символи, както и тирета и долни черти",
      alpha_spaces:
        "Полето може да съдържа само буквени символи, както и интервали",
      between: "Полето трябва да бъде между {min} и {max}",
      confirmed: "Потвърждението на полето не съвпада",
      credit_card: "Номерът на кредитната карта не е валиден",
      digits: "Полето трябва да е цифрово и да съдържа точно {length} цифри",
      dimensions: "Полето трябва да бъде {width} на {height} пиксела",
      email: "Полето трябва да е валиден имейл",
      excluded: "Полето не е валидна стойност",
      ext: "Полето не е валиден файл",
      image: "Полето трябва да бъде изображение",
      integer: "Полето трябва да е цяло число",
      length: "Полето трябва да е дълго {length}",
      max_value: "Полето трябва да бъде {max} или по-малко",
      max: "Полето не трябва да бъде по-голямо от {length} знака",
      mimes: "Полето трябва да има валиден тип файл",
      min_value: "Полето трябва да бъде {min} или повече",
      min: "Полето трябва да съдържа поне {length} знака",
      numeric: "Полето може да съдържа само числови символи",
      oneOf: "Полето не е валидна стойност",
      regex: "Форматът на полето е невалиден",
      required_if: "Полето е задължително",
      required: "Полето е задължително",
      size: "Размерът на полето трябва да е по-малък от {size}KB",
      double: "Полето трябва да бъде валидно десетично число",
      strong_password:
        "Паролата трябва да е дълга поне 8 знака и трябва да съдържа поне: 1 главна буква, 1 малка буква, 1 цифра и 1 специален символ",
      regular_password:
        "Паролата трябва да е дълга поне 8 знака и трябва да съдържа поне: 1 главна буква, 1 малка буква и 1 цифра",
      alpha_num_space:
        "Полето може да съдържа само буквено-цифрови символи и знаци за интервал",
      not_underage: "Притежателят на членството трябва да е на {age} години или по-възрастен"
    }
  },
  bs: {
    code: "bs",
    messages: {
      alpha: "Polje može sadržavati samo abecedne karaktere",
      alpha_num: "Polje može sadržavati samo alfanumeričke karaktere",
      alpha_dash: "Polje može sadržavati alfanumeričke karaktere kao i crtice i donje crte",
      alpha_spaces: "Polje može sadržavati samo abecedne karaktere i razmake",
      between: "Polje mora biti između {min} i {max}",
      confirmed: "Potvrda polja se ne poklapa",
      credit_card: "Broj kreditne kartice nije važeći",
      digits: "Polje mora biti numeričko i tačno sadržavati {length} cifara",
      dimensions: "Polje mora biti {width} piksela širine i {height} piksela visine",
      email: "Polje mora biti važeći e-mail",
      excluded: "Polje nije važeća vrijednost",
      ext: "Polje nije važeća datoteka",
      image: "Polje mora biti slika",
      integer: "Polje mora biti cijeli broj",
      length: "Polje mora biti dužine {length}",
      max_value: "Polje mora biti {max} ili manje",
      max: "Polje ne može biti duže od {length} karaktera",
      mimes: "Polje mora imati važeću vrstu datoteke",
      min_value: "Polje mora biti {min} ili više",
      min: "Polje mora imati barem {length} karaktera",
      numeric: "Polje može sadržavati samo numeričke karaktere",
      oneOf: "Polje nije važeća vrijednost",
      regex: "Format polja je nevažeći",
      required_if: "Polje je obavezno",
      required: "Polje je obavezno",
      size: "Veličina polja mora biti manja od {size}KB",
      double: "Polje mora biti važeći decimalni broj",
      strong_password: "Lozinka mora imati najmanje 8 karaktera i sadržavati barem: 1 veliko slovo, 1 malo slovo, 1 broj i 1 specijalni znak",
      regular_password: "Lozinka mora imati najmanje 8 karaktera i sadržavati barem: 1 veliko slovo, 1 malo slovo i 1 broj",
      alpha_num_space: "Polje može sadržavati samo alfanumeričke karaktere i razmake",
      not_underage: "Vlasnik članstva mora imati {age} godina ili više"
    }
  },
  ca: {
    code: "ca",
    messages: {
      alpha: "El camp només pot contenir caràcters alfabètics",
      alpha_num: "El camp només pot contenir caràcters alfanumèrics",
      alpha_dash: "El camp pot contenir caràcters alfanumèrics, guions i guions baixos",
      alpha_spaces: "El camp només pot contenir caràcters alfabètics i espais",
      between: "El camp ha de ser entre {min} i {max}",
      confirmed: "La confirmació del camp no coincideix",
      credit_card: "El número de targeta de crèdit no és vàlid",
      digits: "El camp ha de ser numèric i contenir exactament {length} dígits",
      dimensions: "El camp ha de ser de {width} píxels d'ample per {height} píxels d'alt",
      email: "El camp ha de ser un correu electrònic vàlid",
      excluded: "El camp no és un valor vàlid",
      ext: "El camp no és un fitxer vàlid",
      image: "El camp ha de ser una imatge",
      integer: "El camp ha de ser un nombre enter",
      length: "El camp ha de ser de {length} caràcters de llarg",
      max_value: "El camp ha de ser {max} o menys",
      max: "El camp no pot ser més gran que {length} caràcters",
      mimes: "El camp ha de tenir un tipus de fitxer vàlid",
      min_value: "El camp ha de ser {min} o més",
      min: "El camp ha de tenir almenys {length} caràcters",
      numeric: "El camp només pot contenir caràcters numèrics",
      oneOf: "El camp no és un valor vàlid",
      regex: "El format del camp és invàlid",
      required_if: "El camp és obligatori",
      required: "El camp és obligatori",
      size: "La mida del camp ha de ser inferior a {size}KB",
      double: "El camp ha de ser un decimal vàlid",
      strong_password: "La contrasenya ha de tenir almenys 8 caràcters i ha de contenir almenys: 1 lletra majúscula, 1 lletra minúscula, 1 número i 1 caràcter especial",
      regular_password: "La contrasenya ha de tenir almenys 8 caràcters i ha de contenir almenys: 1 lletra majúscula, 1 lletra minúscula i 1 número",
      alpha_num_space: "El camp només pot contenir caràcters alfanumèrics i espais",
      not_underage: "El titular de la subscripció ha de tenir {age} anys o més"
    }
  },
  cs: {
    code: "cs",
    messages: {
      alpha: "Pole může obsahovat pouze abecední znaky",
      alpha_num: "Pole může obsahovat pouze alfanumerické znaky",
      alpha_dash: "Pole může obsahovat alfanumerické znaky, stejně jako pomlčky a podtržítka",
      alpha_spaces: "Pole může obsahovat pouze abecední znaky a mezery",
      between: "Pole musí být mezi {min} a {max}",
      confirmed: "Potvrzení pole se neshoduje",
      credit_card: "Číslo kreditní karty není platné",
      digits: "Pole musí být číselné a přesně obsahovat {length} číslic",
      dimensions: "Pole musí mít rozměry {width} pixelů na {height} pixelů",
      email: "Pole musí být platný e-mail",
      excluded: "Pole není platná hodnota",
      ext: "Pole není platný soubor",
      image: "Pole musí být obrázek",
      integer: "Pole musí být celé číslo",
      length: "Pole musí mít délku {length}",
      max_value: "Pole musí být {max} nebo méně",
      max: "Pole nemůže být delší než {length} znaků",
      mimes: "Pole musí mít platný typ souboru",
      min_value: "Pole musí být {min} nebo více",
      min: "Pole musí mít alespoň {length} znaků",
      numeric: "Pole může obsahovat pouze číselné znaky",
      oneOf: "Pole není platná hodnota",
      regex: "Formát pole je neplatný",
      required_if: "Pole je povinné",
      required: "Pole je povinné",
      size: "Velikost pole musí být menší než {size}KB",
      double: "Pole musí být platné desetinné číslo",
      strong_password: "Heslo musí mít alespoň 8 znaků a obsahovat alespoň: 1 velké písmeno, 1 malé písmeno, 1 číslici a 1 speciální znak",
      regular_password: "Heslo musí mít alespoň 8 znaků a obsahovat alespoň: 1 velké písmeno, 1 malé písmeno a 1 číslici",
      alpha_num_space: "Pole může obsahovat pouze alfanumerické znaky a mezery",
      not_underage: "Držitel členství musí být {age} let nebo starší"
    }
  },
  de: {
    code: "de",
    messages: {
      alpha: "Das Feld darf nur alphabetische Zeichen enthalten",
      alpha_num: "Das Feld darf nur alphanumerische Zeichen enthalten",
      alpha_dash:
        "Das Feld kann alphanumerische Zeichen sowie Bindestriche und Unterstriche enthalten",
      alpha_spaces:
        "Das Feld darf nur alphabetische Zeichen sowie Leerzeichen enthalten",
      between: "Das Feld muss zwischen {min} und {max} liegen",
      confirmed: "Das Feld Bestätigung stimmt nicht überein",
      credit_card: "Die Kreditkartennummer ist ungültig",
      digits: "Das Feld muss numerisch sein und genau {length} Ziffern enthalten",
      dimensions: "Das Feld muss {width} Pixel x {height} Pixel sein",
      email: "Das Feld muss eine gültige E-Mail-Adresse sein",
      excluded: "Das Feld ist kein gültiger Wert",
      ext: "Das Feld ist keine gültige Datei",
      image: "Das Feld muss ein Bild sein",
      integer: "Das Feld muss eine ganze Zahl sein",
      length: "Das Feld muss {length} lang sein",
      max_value: "Das Feld muss {max} oder kleiner sein",
      max: "Das Feld darf nicht länger als {length} Zeichen sein",
      mimes: "Das Feld muss einen gültigen Dateityp haben",
      min_value: "Das Feld muss {min} oder mehr sein",
      min: "Das Feld muss mindestens {length} Zeichen enthalten",
      numeric: "Das Feld darf nur numerische Zeichen enthalten",
      oneOf: "Das Feld ist kein gültiger Wert.",
      regex: "Das Feldformat ist ungültig",
      required_if: "Das Feld ist erforderlich",
      required: "Das Feld ist erforderlich",
      size: "Die Feldgröße muss kleiner als {size}KB sein.",
      double: "Das Feld muss eine gültige Dezimalzahl sein",
      strong_password:
        "Das Passwort muss mindestens 8 Zeichen lang sein und mindestens Folgendes enthalten: 1 Großbuchstabe,  1 Kleinbuchstabe, 1 Zahl und 1 Sonderzeichen",
      regular_password:
        "Das Passwort muss mindestens 8 Zeichen lang sein und mindestens Folgendes enthalten: 1 Großbuchstabe, 1 Kleinbuchstabe und 1 Zahl",
      alpha_num_space:
        "Das Feld darf nur alphanumerische Zeichen und Leerzeichen enthalten",
      not_underage: "Der Mitgliedschaftsinhaber muss mindestens {Alter} Jahre alt sein"
    }
  },
  es: {
    code: "es",
    messages: {
      alpha: "Este campo solo debe contener letras",
      alpha_dash: "Este campo solo debe contener letras, números y guiones",
      alpha_num: "Este campo solo debe contener letras y números",
      alpha_spaces: "Este campo solo debe contener letras y espacios",
      between: "Este campo debe estar entre {min} y {max}",
      confirmed: "Este campo no coincide",
      credit_card: "El numero de tarjeta no es válido",
      digits: "Este campo debe ser numérico y contener exactamente {length} dígitos",
      dimensions: "Este campo debe ser de {width} píxeles por {height} píxeles",
      email: "Este campo debe ser un correo electrónico válido",
      excluded: "Este campo debe ser un valor válido",
      ext: "Este campo debe ser un archivo válido",
      image: "Este campo debe ser una imagen",
      oneOf: "Este campo debe ser un valor válido",
      integer: "Este campo debe ser un entero",
      length: "El largo de este campo debe ser {length}",
      max: "Este campo no debe ser mayor a {length} caracteres",
      max_value: "Este campo debe de ser {max} o menor",
      mimes: "Este campo debe ser un tipo de archivo válido",
      min: "Este campo debe tener al menos {length} caracteres",
      min_value: "Este campo debe ser {min} o superior",
      numeric: "Este campo debe contener solo caracteres numéricos",
      regex: "El formato de este campo no es válido",
      required: "Este campo es obligatorio",
      required_if: "Este campo es obligatorio",
      size: "Este campo debe ser menor a {size}KB",
      double: "Este campo debe ser un decimal válido",
      strong_password: "La contraseña debe ser de al menos 8 caracteres, y debe contener al menos: 1 letra mayúscula, 1 letra minúscula, 1 número, y 1 caracter especial",
      regular_password: "La contraseña debe ser de al menos 8 caracteres, y debe contener al menos: 1 letra mayúscula, 1 letra minúscula y 1 número",
      alpha_num_space: "Este campo solo debe contener letras, números y espacios",
      not_underage: "El titular de la membresía debe tener {age} años o más"
    }
  },
  el: {
    code: "el",
    messages: {
      alpha: "Το πεδίο μπορεί να περιέχει μόνο αλφαβητικούς χαρακτήρες",
      alpha_num: "Το πεδίο μπορεί να περιέχει μόνο αλφαριθμητικούς χαρακτήρες",
      alpha_dash:
        "Το πεδίο μπορεί να περιέχει αλφαριθμητικούς χαρακτήρες, παύλες, καθώς και παύλες υπογράμμισης",
      alpha_spaces:
        "Το πεδίο μπορεί να περιέχει μόνο αλφαβητικούς χαρακτήρες και κενά",
      between: "Το πεδίο πρέπει να είναι μεταξύ {min} και {max}",
      confirmed: "Η επιβεβαίωση πεδίου δεν ταιριάζει",
      credit_card: "Ο αριθμός της πιστωτικής κάρτας δεν είναι έγκυρος",
      digits: "Το πεδίο πρέπει να είναι αριθμητικό και να περιέχει ακριβώς {length} ψηφία",
      dimensions: "Το πεδίο πρέπει να είναι {width} pixel επί {height} pixel",
      email: "Το πεδίο πρέπει να είναι ένα έγκυρο email",
      excluded: "Το πεδίο δεν είναι έγκυρη τιμή",
      ext: "Το πεδίο δεν είναι έγκυρο αρχείο",
      image: "Το πεδίο πρέπει να είναι εικόνα",
      integer: "Το πεδίο πρέπει να είναι ακέραιος αριθμός",
      length: "Το πεδίο πρέπει να έχει μήκος {length}",
      max_value: "Το πεδίο πρέπει να είναι {max} ή μικρότερο",
      max: "Το πεδίο δεν μπορεί να περιέχει περισσότερους από {length} χαρακτήρες",
      mimes: "Το πεδίο πρέπει να έχει έγκυρο τύπο αρχείου",
      min_value: "Το πεδίο πρέπει να είναι {min} ή περισσότερο",
      min: "Το πεδίο πρέπει να αποτελείται από τουλάχιστον {length} χαρακτήρες",
      numeric: "Το πεδίο μπορεί να περιέχει μόνο αριθμητικούς χαρακτήρες",
      oneOf: "Το πεδίο δεν είναι έγκυρη τιμή",
      regex: "Η μορφή πεδίου δεν είναι έγκυρη",
      required_if: "Το πεδίο είναι υποχρεωτικό",
      required: "Το πεδίο είναι υποχρεωτικό",
      size: "Το μέγεθος του πεδίου πρέπει να είναι μικρότερο από {size}KB",
      double: "Το πεδίο πρέπει να είναι έγκυρο δεκαδικό",
      strong_password:
        "Ο κωδικός πρόσβασης πρέπει να αποτελείται από τουλάχιστον 8 χαρακτήρες και πρέπει να περιέχει τουλάχιστον: 1 κεφαλαίο γράμμα, 1 πεζό γράμμα, 1 αριθμό και 1 ειδικό χαρακτήρα",
      regular_password:
        "Ο κωδικός πρόσβασης πρέπει να αποτελείται από τουλάχιστον 8 χαρακτήρες και πρέπει να περιέχει τουλάχιστον: 1 κεφαλαίο γράμμα, 1 πεζό γράμμα και 1 αριθμό",
      alpha_num_space:
        "Το πεδίο μπορεί να περιέχει μόνο αλφαριθμητικούς χαρακτήρες και κενά",
      not_underage: "Ο κάτοχος της συνδρομής πρέπει να είναι {ηλικία} ετών ή μεγαλύτερος"
    }
  },
  en: {
    code: "en",
    messages: {
      alpha: "The field may only contain alphabetic characters",
      alpha_num: "The field may only contain alpha-numeric characters",
      alpha_dash: "The field may contain alpha-numeric characters as well as dashes and underscores",
      alpha_spaces: "The field may only contain alphabetic characters as well as spaces",
      between: "The field must be between {min} and {max}",
      confirmed: "The field confirmation does not match",
      credit_card: "The credit card number is not valid",
      digits: "The field must be numeric and exactly contain {length} digits",
      dimensions: "The field must be {width} pixels by {height} pixels",
      email: "The field must be a valid email",
      excluded: "The field is not a valid value",
      ext: "The field is not a valid file",
      image: "The field must be an image",
      integer: "The field must be an integer",
      length: "The field must be {length} long",
      max_value: "The field must be {max} or less",
      max: "The field may not be greater than {length} characters",
      mimes: "The field must have a valid file type",
      min_value: "The field must be {min} or more",
      min: "The field must be at least {length} characters",
      numeric: "The field may only contain numeric characters",
      oneOf: "The field is not a valid value",
      regex: "The field format is invalid",
      required_if: "The field is required",
      required: "The field is required",
      size: "The field size must be less than {size}KB",
      double: "The field must be a valid decimal",
      strong_password: "The password must be at least 8 characters long, and must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character",
      regular_password: "The password must be at least 8 characters long, and must contain at least: 1 uppercase letter, 1 lowercase letter and 1 number",
      alpha_num_space: "The field may only contain alpha-numeric and space characters",
      not_underage: "The membership holder must be {age} years or older"
    }
  },
  fr: {
    code: "fr",
    messages: {
      alpha: "Le champ peut uniquement contenir des caractères alphabétiques",
      alpha_num: "Le champ peut uniquement contenir des caractères alphanumériques",
      alpha_dash: "Le champ peut contenir des caractères alphanumériques ainsi que des tirets et des underscores",
      alpha_spaces: "Le champ peut uniquement contenir des caractères alphabétiques ainsi que des espaces",
      between: "Le champ doit être compris entre {min} et {max}",
      confirmed: "La confirmation du champ ne correspond pas",
      credit_card: "Le numéro de carte de crédit n'est pas valide",
      digits: "Le champ doit être numérique et contenir exactement {length} chiffres",
      dimensions: "Le champ doit être de {width} pixels de large par {height} pixels de haut",
      email: "Le champ doit être une adresse email valide",
      excluded: "Le champ n'est pas une valeur valide",
      ext: "Le champ n'est pas un fichier valide",
      image: "Le champ doit être une image",
      integer: "Le champ doit être un nombre entier",
      length: "Le champ doit être de {length} caractères de long",
      max_value: "Le champ doit être {max} ou moins",
      max: "Le champ ne peut pas dépasser {length} caractères",
      mimes: "Le champ doit avoir un type de fichier valide",
      min_value: "Le champ doit être {min} ou plus",
      min: "Le champ doit contenir au moins {length} caractères",
      numeric: "Le champ peut uniquement contenir des caractères numériques",
      oneOf: "Le champ n'est pas une valeur valide",
      regex: "Le format du champ est invalide",
      required_if: "Le champ est requis",
      required: "Le champ est requis",
      size: "La taille du champ doit être inférieure à {size}KB",
      double: "Le champ doit être un nombre décimal valide",
      strong_password: "Le mot de passe doit contenir au moins 8 caractères, dont au moins : 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et 1 caractère spécial",
      regular_password: "Le mot de passe doit contenir au moins 8 caractères, dont au moins : 1 lettre majuscule, 1 lettre minuscule et 1 chiffre",
      alpha_num_space: "Le champ peut uniquement contenir des caractères alphanumériques et des espaces",
      not_underage: "Le titulaire de l'abonnement doit avoir {age} ans ou plus"
    }
  },
  he: {
    code: "he",
    messages: {
      alpha: "השדה יכול להכיל רק תווים אלפביתיים",
      alpha_num: "השדה יכול להכיל רק תווים אלפאנומריים",
      alpha_dash: "השדה יכול להכיל תווים אלפאנומריים וכן מקפים וקווים תחתונים",
      alpha_spaces: "השדה יכול להכיל רק תווים אלפביתיים ורווחים",
      between: "השדה חייב להיות בין {min} ל-{max}",
      confirmed: "אישור השדה אינו תואם",
      credit_card: "מספר כרטיס האשראי אינו חוקי",
      digits: "השדה חייב להיות מספרי ולהכיל בדיוק {length} ספרות",
      dimensions: "השדה חייב להיות ברוחב {width} פיקסלים וגובה {height} פיקסלים",
      email: "השדה חייב להיות כתובת אימייל חוקית",
      excluded: "השדה אינו ערך חוקי",
      ext: "השדה אינו קובץ חוקי",
      image: "השדה חייב להיות תמונה",
      integer: "השדה חייב להיות מספר שלם",
      length: "השדה חייב להיות באורך {length}",
      max_value: "השדה חייב להיות {max} או פחות",
      max: "השדה לא יכול להיות יותר מ-{length} תווים",
      mimes: "השדה חייב להיות סוג קובץ חוקי",
      min_value: "השדה חייב להיות {min} או יותר",
      min: "השדה חייב להיות לפחות {length} תווים",
      numeric: "השדה יכול להכיל רק תווים מספריים",
      oneOf: "השדה אינו ערך חוקי",
      regex: "פורמט השדה אינו חוקי",
      required_if: "השדה נדרש",
      required: "השדה נדרש",
      size: "גודל השדה חייב להיות קטן מ-{size}KB",
      double: "השדה חייב להיות מספר עשרוני חוקי",
      strong_password: "הסיסמה חייבת להיות באורך של לפחות 8 תווים, ולכלול לפחות: אות אחת גדולה, אות אחת קטנה, מספר אחד ותו מיוחד אחד",
      regular_password: "הסיסמה חייבת להיות באורך של לפחות 8 תווים, ולכלול לפחות: אות אחת גדולה, אות אחת קטנה ומספר אחד",
      alpha_num_space: "השדה יכול להכיל רק תווים אלפאנומריים ורווחים",
      not_underage: "בעל המנוי חייב להיות בגיל {age} או יותר"
    }
  },
  hu: {
    code: "hu",
    messages: {
      alpha: "A mező csak alfabetikus karaktereket tartalmazhat",
      alpha_num: "A mező csak alfanumerikus karaktereket tartalmazhat",
      alpha_dash:
        "A mező tartalmazhat alfanumerikus karaktereket, valamint kötőjeleket és aláhúzásjeleket",
      alpha_spaces:
        "A mező csak alfabetikus karaktereket és szóközöket tartalmazhat",
      between: "A mező minimum {min} és maximum {max} karaktert tartalmazhat",
      confirmed: "A mező és a mező megerősítésének tartalma nem egyezik",
      credit_card: "A bankkártya száma érvénytelen",
      digits: "A mezőnek numerikusnak kell lennie, és pontosan {length} számjegyet kell tartalmaznia",
      dimensions: "A mezőnek {width} x {height} képpontot kell tartalmaznia",
      email: "A mezőnek érvényes e-mail-címet kell tartalmaznia",
      excluded: "A mező tartalma nem érvényes érték",
      ext: "A mező tartalma nem érvényes fájl",
      image: "A mezőnek képnek kell lennie",
      integer: "A mezőnek egész számot kell tartalmaznia",
      length: "A mező tartalmának {length} hosszúnak kell lennie",
      max_value: "A mező legfeljebb {max} hosszúságú lehet",
      max: "A mező legfeljebb {length} karaktert tartalmazhat",
      mimes: "A mezőnek érvényes fájltípust kell tartalmaznia",
      min_value: "A mezőnek legalább {min} hosszúnak kell lennie",
      min: "A mezőnek legalább {length} karaktert kell tartalmaznia",
      numeric: "A mező csak numerikus karaktereket tartalmazhat",
      oneOf: "A mező tartalma nem érvényes érték",
      regex: "A mező formátuma érvénytelen",
      required_if: "A mező kitöltése kötelező",
      required: "A mező kitöltése kötelező",
      size: "A mező mérete nem haladhatja meg a(z) {size}KB-ot",
      double: "A mezőnek érvényes tizedesjegyet kell tartalmaznia",
      strong_password:
        "A jelszónak legalább 8 karakter hosszúnak kell lennie, és tartalmaznia kell legalább: 1 nagybetűt, 1 kisbetűt, 1 számot és 1 különleges karaktert",
      regular_password:
        "A jelszónak legalább 8 karakter hosszúnak kell lennie, és tartalmaznia kell legalább: 1 nagybetűt, 1 kisbetűt és 1 számot",
      alpha_num_space:
        "A mező csak alfanumerikus karaktereket és szóközöket tartalmazhat",
      not_underage: "A tagnak legalább {age} évesnek kell lennie"
    }
  },
  it: {
    code: "it",
    messages: {
      alpha: "Il campo può contenere solo caratteri alfabetic",
      alpha_num: "Il campo può contenere solo caratteri alfanumerici",
      alpha_dash:
        "Il campo può contenere caratteri alfanumerici, nonché trattini e caratteri di sottolineatura",
      alpha_spaces:
        "Il campo può contenere solo caratteri alfabetici oltre agli spazi",
      between: "Il campo deve essere compreso tra {min} e {max}",
      confirmed: "Il campo di conferma non corrisponde",
      credit_card: "Il numero della carta di credito non è valido",
      digits:
        "Til campo deve essere numerico e contenere esattamente {length} cifre",
      dimensions: "Il campo deve essere {width} pixel per {height} pixe",
      email: "Il campo deve essere un indirizzo email valido",
      excluded: "Il campo non è un valore valido",
      ext: "Il campo non è un file valido",
      image: "Il campo deve essere un'immagine",
      integer: "Il campo deve essere un numero intero",
      length: "Il campo deve essere lungo {length}",
      max_value: "Il campo deve essere {max} o inferiore",
      max: "Il campo non può contenere più di {length} caratteri",
      mimes: "Il campo deve avere un tipo di file valido",
      min_value: "Il campo deve essere {min} o più",
      min: "Il campo deve contenere almeno {length} caratteri",
      numeric: "Il campo può contenere solo caratteri numerici",
      oneOf: "Il campo non è un valore valido",
      regex: "formato del campo non valido",
      required_if: "Il campo è obbligatorio",
      required: "Il campo è obbligatorio",
      size: "La dimensione del campo deve essere inferiore a {size} KB",
      double: "Il campo deve essere un decimale valido",
      strong_password:
        "La password deve essere lunga almeno 8 caratteri e deve contenere almeno: 1 lettera maiuscola, 1 lettera minuscola, 1 numero e 1 carattere speciale",
      regular_password:
        "La password deve essere lunga almeno 8 caratteri e deve contenere almeno: 1 lettera maiuscola, 1 lettera minuscola e 1 numero",
      alpha_num_space:
        "Il campo può contenere solo caratteri alfanumerici e spazio",
      not_underage: "Il titolare dell'iscrizione deve avere almeno {age} anni"
    }
  },
  ka: {
    code: "ka",
    messages: {
      alpha: "ველს შეუძლია შეიცავდეს მხოლოდ ანბანურ სიმბოლოებს",
      alpha_num: "ველს შეუძლია შეიცავდეს მხოლოდ ანბანურ-ციფრულ სიმბოლოებს",
      alpha_dash: "ველს შეუძლია შეიცავდეს ანბანურ-ციფრულ სიმბოლოებს, ასევე ტირეებს და ქვედა ხაზებს",
      alpha_spaces: "ველს შეუძლია შეიცავდეს მხოლოდ ანბანურ სიმბოლოებს და სივრცეებს",
      between: "ველი უნდა იყოს {min} და {max} შორის",
      confirmed: "ველის დადასტურება არ ემთხვევა",
      credit_card: "საკრედიტო ბარათის ნომერი არასწორია",
      digits: "ველს უნდა შეიცავდეს მხოლოდ რიცხვები და ზუსტად {length} ციფრები",
      dimensions: "ველის ზომები უნდა იყოს {width} პიქსელი სიგანეში და {height} პიქსელი სიმაღლეში",
      email: "ველი უნდა იყოს ვალიდური ელფოსტის მისამართი",
      excluded: "ველი არ არის ვალიდური მნიშვნელობა",
      ext: "ველი არ არის ვალიდური ფაილი",
      image: "ველი უნდა იყოს სურათი",
      integer: "ველი უნდა იყოს მთელი რიცხვი",
      length: "ველი უნდა იყოს {length} სიმბოლოს სიგრძის",
      max_value: "ველი უნდა იყოს {max} ან ნაკლები",
      max: "ველი არ შეიძლება იყოს მეტი ვიდრე {length} სიმბოლო",
      mimes: "ველს უნდა ჰქონდეს ვალიდური ფაილის ტიპი",
      min_value: "ველი უნდა იყოს {min} ან მეტი",
      min: "ველი უნდა იყოს მინიმუმ {length} სიმბოლო",
      numeric: "ველს შეუძლია შეიცავდეს მხოლოდ რიცხვებს",
      oneOf: "ველი არ არის ვალიდური მნიშვნელობა",
      regex: "ველის ფორმატი არასწორია",
      required_if: "ველი აუცილებელია",
      required: "ველი აუცილებელია",
      size: "ველის ზომა უნდა იყოს ნაკლები {size}KB",
      double: "ველი უნდა იყოს ვალიდური ათობითი რიცხვი",
      strong_password: "პაროლი უნდა იყოს მინიმუმ 8 სიმბოლო სიგრძის და შეიცავდეს მინიმუმ: 1 დიდი ასო, 1 პატარა ასო, 1 რიცხვი და 1 სპეციალური სიმბოლო",
      regular_password: "პაროლი უნდა იყოს მინიმუმ 8 სიმბოლო სიგრძის და შეიცავდეს მინიმუმ: 1 დიდი ასო, 1 პატარა ასო და 1 რიცხვი",
      alpha_num_space: "ველს შეუძლია შეიცავდეს მხოლოდ ანბანურ-ციფრულ და სივრცეების სიმბოლოებს",
      not_underage: "წევრობის მფლობელი უნდა იყოს {age} წლის ან მეტი"
    }
  },
  lt: {
    code: "lt",
    messages: {
      alpha: "Laukas gali turėti tik raidinius simbolius",
      alpha_num: "Laukas gali turėti tik raidinius ir skaitmeninius simbolius",
      alpha_dash: "Laukas gali turėti raidinius ir skaitmeninius simbolius, taip pat brūkšnelius ir pabraukimus",
      alpha_spaces: "Laukas gali turėti tik raidinius simbolius ir tarpus",
      between: "Laukas turi būti tarp {min} ir {max}",
      confirmed: "Lauko patvirtinimas nesutampa",
      credit_card: "Kredito kortelės numeris nėra galiojantis",
      digits: "Laukas turi būti skaitmeninis ir turi būti tiksliai {length} skaitmenų",
      dimensions: "Laukas turi būti {width} pikselių pločio ir {height} pikselių aukščio",
      email: "Laukas turi būti galiojantis el. paštas",
      excluded: "Laukas nėra galiojanti vertė",
      ext: "Laukas nėra galiojantis failas",
      image: "Laukas turi būti vaizdas",
      integer: "Laukas turi būti sveikasis skaičius",
      length: "Laukas turi būti {length} simbolių ilgio",
      max_value: "Laukas turi būti {max} arba mažiau",
      max: "Laukas negali būti ilgesnis nei {length} simbolių",
      mimes: "Laukas turi būti galiojantis failo tipas",
      min_value: "Laukas turi būti {min} arba daugiau",
      min: "Laukas turi būti bent {length} simbolių ilgio",
      numeric: "Laukas gali turėti tik skaitmeninius simbolius",
      oneOf: "Laukas nėra galiojanti vertė",
      regex: "Lauko formatas yra neteisingas",
      required_if: "Laukas yra privalomas",
      required: "Laukas yra privalomas",
      size: "Lauko dydis turi būti mažesnis nei {size}KB",
      double: "Laukas turi būti galiojantis dešimtainis skaičius",
      strong_password: "Slaptažodis turi būti bent 8 simbolių ilgio ir turi turėti bent: 1 didžiąją raidę, 1 mažąją raidę, 1 skaičių ir 1 specialų simbolį",
      regular_password: "Slaptažodis turi būti bent 8 simbolių ilgio ir turi turėti bent: 1 didžiąją raidę, 1 mažąją raidę ir 1 skaičių",
      alpha_num_space: "Laukas gali turėti tik raidinius, skaitmeninius simbolius ir tarpus",
      not_underage: "Narystės turėtojas turi būti {age} metų ar vyresnis"
    }
  },
  lv: {
    code: "lv",
    messages: {
      alpha: "Lauks var saturēt tikai alfabēta rakstzīmes",
      alpha_num: "Lauks var saturēt tikai burtu-ciparu rakstzīmes",
      alpha_dash: "Lauks var saturēt burtu-ciparu rakstzīmes, kā arī domuzīmes un apakšsvītras",
      alpha_spaces: "Lauks var saturēt tikai alfabēta rakstzīmes un atstarpes",
      between: "Laukam jābūt starp {min} un {max}",
      confirmed: "Lauka apstiprinājums nesakrīt",
      credit_card: "Kredītkartes numurs nav derīgs",
      digits: "Laukam jābūt skaitliskam un precīzi saturēt {length} ciparus",
      dimensions: "Laukam jābūt {width} pikseļus platam un {height} pikseļus augstam",
      email: "Laukam jābūt derīgam e-pastam",
      excluded: "Lauks nav derīga vērtība",
      ext: "Lauks nav derīgs fails",
      image: "Laukam jābūt attēlam",
      integer: "Laukam jābūt veselam skaitlim",
      length: "Laukam jābūt {length} garam",
      max_value: "Laukam jābūt {max} vai mazāk",
      max: "Lauks nedrīkst būt garāks par {length} rakstzīmēm",
      mimes: "Laukam jābūt derīgam faila tipam",
      min_value: "Laukam jābūt {min} vai vairāk",
      min: "Laukam jābūt vismaz {length} rakstzīmes garam",
      numeric: "Lauks var saturēt tikai skaitliskas rakstzīmes",
      oneOf: "Lauks nav derīga vērtība",
      regex: "Lauka formāts ir nederīgs",
      required_if: "Lauks ir obligāts",
      required: "Lauks ir obligāts",
      size: "Lauka izmēram jābūt mazākam par {size}KB",
      double: "Laukam jābūt derīgam decimālskaitlim",
      strong_password: "Parolei jābūt vismaz 8 rakstzīmēm garai un jāietver vismaz: 1 lielo burtu, 1 mazo burtu, 1 ciparu un 1 speciālo rakstzīmi",
      regular_password: "Parolei jābūt vismaz 8 rakstzīmēm garai un jāietver vismaz: 1 lielo burtu, 1 mazo burtu un 1 ciparu",
      alpha_num_space: "Lauks var saturēt tikai burtu-ciparu rakstzīmes un atstarpes",
      not_underage: "Dalības īpašniekam jābūt {age} gadus vecam vai vecākam"
    }
  },
  mk: {
    code: "mk",
    messages: {
      alpha: "Полето може да содржи само букви",
      alpha_num: "Полето може да содржи само алфанумерички знаци",
      alpha_dash: "Полето може да содржи алфанумерички знаци како и цртички и подвлеки",
      alpha_spaces: "Полето може да содржи само букви и празни места",
      between: "Полето мора да биде помеѓу {min} и {max}",
      confirmed: "Потврдата на полето не се совпаѓа",
      credit_card: "Бројот на кредитната картичка не е валиден",
      digits: "Полето мора да биде нумеричко и точно да содржи {length} цифри",
      dimensions: "Полето мора да биде {width} пиксели на {height} пиксели",
      email: "Полето мора да биде валидна е-пошта",
      excluded: "Полето не е валидна вредност",
      ext: "Полето не е валидна датотека",
      image: "Полето мора да биде слика",
      integer: "Полето мора да биде цел број",
      length: "Полето мора да биде долго {length}",
      max_value: "Полето мора да биде {max} или помалку",
      max: "Полето не смее да биде поголемо од {length} знаци",
      mimes: "Полето мора да има валиден тип на датотека",
      min_value: "Полето мора да биде {min} или повеќе",
      min: "Полето мора да има најмалку {length} знаци",
      numeric: "Полето може да содржи само нумерички знаци",
      oneOf: "Полето не е валидна вредност",
      regex: "Форматот на полето е невалиден",
      required_if: "Полето е задолжително",
      required: "Полето е задолжително",
      size: "Големината на полето мора да биде помала од {size}KB",
      double: "Полето мора да биде валиден децимален број",
      strong_password: "Лозинката мора да биде најмалку 8 знаци и мора да содржи најмалку: 1 голема буква, 1 мала буква, 1 број и 1 специјален знак",
      regular_password: "Лозинката мора да биде најмалку 8 знаци и мора да содржи најмалку: 1 голема буква, 1 мала буква и 1 број",
      alpha_num_space: "Полето може да содржи само алфанумерички знаци и празни места",
      not_underage: "Носителот на членството мора да биде {age} години или постар"
    }
  },
  nl: {
    code: "nl",
    messages: {
      alpha: "Het veld mag alleen alfabetische tekens bevatten",
      alpha_num: "Het veld mag alleen alfanumerieke tekens bevatten",
      alpha_dash: "Het veld mag alfanumerieke tekens bevatten evenals streepjes en onderstrepingstekens",
      alpha_spaces: "Het veld mag alleen alfabetische tekens en spaties bevatten",
      between: "Het veld moet tussen {min} en {max} zijn",
      confirmed: "De veldbevestiging komt niet overeen",
      credit_card: "Het creditcardnummer is niet geldig",
      digits: "Het veld moet numeriek zijn en exact {length} cijfers bevatten",
      dimensions: "Het veld moet {width} pixels bij {height} pixels zijn",
      email: "Het veld moet een geldig e-mailadres zijn",
      excluded: "Het veld is geen geldige waarde",
      ext: "Het veld is geen geldig bestand",
      image: "Het veld moet een afbeelding zijn",
      integer: "Het veld moet een geheel getal zijn",
      length: "Het veld moet {length} lang zijn",
      max_value: "Het veld moet {max} of minder zijn",
      max: "Het veld mag niet meer dan {length} tekens bevatten",
      mimes: "Het veld moet een geldig bestandstype hebben",
      min_value: "Het veld moet {min} of meer zijn",
      min: "Het veld moet minstens {length} tekens bevatten",
      numeric: "Het veld mag alleen numerieke tekens bevatten",
      oneOf: "Het veld is geen geldige waarde",
      regex: "Het veldformaat is ongeldig",
      required_if: "Het veld is verplicht",
      required: "Het veld is verplicht",
      size: "De veldgrootte moet minder dan {size}KB zijn",
      double: "Het veld moet een geldig decimaal getal zijn",
      strong_password: "Het wachtwoord moet minstens 8 tekens lang zijn en moet ten minste bevatten: 1 hoofdletter, 1 kleine letter, 1 nummer en 1 speciaal teken",
      regular_password: "Het wachtwoord moet minstens 8 tekens lang zijn en moet ten minste bevatten: 1 hoofdletter, 1 kleine letter en 1 nummer",
      alpha_num_space: "Het veld mag alleen alfanumerieke tekens en spaties bevatten",
      not_underage: "De houder van het lidmaatschap moet {age} jaar of ouder zijn"
    }
  },
  nb: {
    code: "nb",
    messages: {
      alpha: "Feltet kan kun inneholde alfabetiske tegn",
      alpha_num: "Feltet kan kun inneholde alfanumeriske tegn",
      alpha_dash: "Feltet kan inneholde alfanumeriske tegn samt bindestreker og understreker",
      alpha_spaces: "Feltet kan kun inneholde alfabetiske tegn samt mellomrom",
      between: "Feltet må være mellom {min} og {max}",
      confirmed: "Bekreftelsen av feltet stemmer ikke overens",
      credit_card: "Kredittkortnummeret er ikke gyldig",
      digits: "Feltet må være numerisk og inneholde nøyaktig {length} sifre",
      dimensions: "Feltet må være {width} piksler bredt og {height} piksler høyt",
      email: "Feltet må være en gyldig e-postadresse",
      excluded: "Feltet er ikke en gyldig verdi",
      ext: "Feltet er ikke en gyldig fil",
      image: "Feltet må være et bilde",
      integer: "Feltet må være et heltall",
      length: "Feltet må være {length} langt",
      max_value: "Feltet må være {max} eller mindre",
      max: "Feltet kan ikke være lengre enn {length} tegn",
      mimes: "Feltet må ha en gyldig filtype",
      min_value: "Feltet må være {min} eller mer",
      min: "Feltet må være minst {length} tegn",
      numeric: "Feltet kan kun inneholde numeriske tegn",
      oneOf: "Feltet er ikke en gyldig verdi",
      regex: "Formatet på feltet er ugyldig",
      required_if: "Feltet er påkrevd",
      required: "Feltet er påkrevd",
      size: "Feltet må være mindre enn {size}KB",
      double: "Feltet må være et gyldig desimaltall",
      strong_password: "Passordet må være minst 8 tegn langt, og må inneholde minst: 1 stor bokstav, 1 liten bokstav, 1 tall og 1 spesialtegn",
      regular_password: "Passordet må være minst 8 tegn langt, og må inneholde minst: 1 stor bokstav, 1 liten bokstav og 1 tall",
      alpha_num_space: "Feltet kan kun inneholde alfanumeriske tegn og mellomrom",
      not_underage: "Medlemskapsinnehaveren må være {age} år eller eldre"
    }
  },
  pl: {
    code: "pl",
    messages: {
      alpha: "Pole może zawierać tylko znaki alfabetu",
      alpha_num: "Pole może zawierać wyłącznie znaki alfanumeryczne",
      alpha_dash:
        "Pole może zawierać znaki alfanumeryczne oraz myślniki i podkreślenia",
      alpha_spaces: "Pole może zawierać tylko znaki alfabetu oraz spacje",
      between: "Pole musi mieścić się w przedziale od {min} do {max}",
      confirmed: "Potwierdzenie pola nie pasuje",
      credit_card: "Numer karty kredytowej jest nieprawidłowy",
      digits: "Pole musi być numeryczne i dokładnie zawierać cyfry {length}",
      dimensions:
        "Pole musi mieć wymiary {szerokość} pikseli na {wysokość} pikseli",
      email: "Pole musi zawierać prawidłowy adres e-mail",
      excluded: "Pole nie jest prawidłową wartością",
      ext: "To pole nie jest prawidłowym plikiem",
      image: "Pole musi być obrazem",
      integer: "Pole musi być liczbą całkowitą",
      length: "Pole musi mieć długość {length}",
      max_value: "Pole musi mieć wartość {max} lub mniejszą",
      max: "Pole nie może być większe niż {length} znaków",
      mimes: "Pole musi mieć prawidłowy typ pliku",
      min_value: "Pole musi wynosić {min} lub więcej",
      min: "Pole musi zawierać co najmniej {length} znaków",
      numeric: "Pole może zawierać tylko znaki numeryczne",
      oneOf: "Pole nie jest prawidłową wartością",
      regex: "Format pola jest nieprawidłowy",
      required_if: "To pole jest wymagane",
      required: "To pole jest wymagane",
      size: "Rozmiar pola musi być mniejszy niż {size}KB",
      double: "Pole musi być prawidłową liczbą dziesiętną",
      strong_password:
        "Hasło musi mieć co najmniej 8 znaków i musi zawierać co najmniej: 1 wielką literę, 1 małą literę, 1 cyfrę i 1 znak specjalny",
      regular_password:
        "Hasło musi mieć co najmniej 8 znaków i musi zawierać co najmniej: 1 wielką literę, 1 małą literę i 1 cyfrę",
      alpha_num_space:
        "Pole może zawierać wyłącznie znaki alfanumeryczne i spacje",
      not_underage: "Posiadacz członkostwa musi mieć co najmniej {age} lat"
    }
  },
  pt: {
    code: "pt",
    messages: {
      alpha: "O campo pode conter apenas caracteres alfabéticos",
      alpha_num: "O campo pode conter apenas caracteres alfanuméricos",
      alpha_dash: "O campo pode conter caracteres alfanuméricos, bem como traços e sublinhados",
      alpha_spaces: "O campo pode conter apenas caracteres alfabéticos e espaços",
      between: "O campo deve estar entre {min} e {max}",
      confirmed: "A confirmação do campo não corresponde",
      credit_card: "O número do cartão de crédito não é válido",
      digits: "O campo deve ser numérico e conter exatamente {length} dígitos",
      dimensions: "O campo deve ter {width} pixels por {height} pixels",
      email: "O campo deve ser um e-mail válido",
      excluded: "O campo não é um valor válido",
      ext: "O campo não é um arquivo válido",
      image: "O campo deve ser uma imagem",
      integer: "O campo deve ser um número inteiro",
      length: "O campo deve ter {length} caracteres",
      max_value: "O campo deve ser {max} ou menos",
      max: "O campo não pode ter mais que {length} caracteres",
      mimes: "O campo deve ter um tipo de arquivo válido",
      min_value: "O campo deve ser {min} ou mais",
      min: "O campo deve ter pelo menos {length} caracteres",
      numeric: "O campo pode conter apenas caracteres numéricos",
      oneOf: "O campo não é um valor válido",
      regex: "O formato do campo é inválido",
      required_if: "O campo é obrigatório",
      required: "O campo é obrigatório",
      size: "O campo deve ser menor que {size}KB",
      double: "O campo deve ser um decimal válido",
      strong_password: "A senha deve ter pelo menos 8 caracteres e deve conter pelo menos: 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial",
      regular_password: "A senha deve ter pelo menos 8 caracteres e deve conter pelo menos: 1 letra maiúscula, 1 letra minúscula e 1 número",
      alpha_num_space: "O campo pode conter apenas caracteres alfanuméricos e espaços",
      not_underage: "O titular da associação deve ter {age} anos ou mais"
    }
  },
  ro: {
    code: "ro",
    messages: {
      alpha: "Câmpul poate conține numai caractere alfabetice",
      alpha_num: "Câmpul poate conține numai caractere alfanumerice",
      alpha_dash:
        "Câmpul poate conține caractere alfanumerice, precum și liniuțe și caractere subliniate",
      alpha_spaces:
        "Câmpul poate conține numai caractere alfabetice, precum și spații",
      between: "Câmpul trebuie să fie între {min} și {max}",
      confirmed: "Cele două câmpuri nu coincid",
      credit_card: "Numărul cardului de credit nu este valid",
      digits: "Câmpul trebuie să fie numeric și să conțină exact {length} cifre",
      dimensions: "Câmpul trebuie să aibă mărimea de {width} pixeli pe {height} pixeli",
      email: "Câmpul trebuie să fie o adresă de e-mail validă",
      excluded: "Câmpul nu este o valoare validă",
      ext: "Câmpul nu este un fișier valid",
      image: "Câmpul trebuie să fie o imagine",
      integer: "Câmpul trebuie să fie un număr întreg",
      length: "Câmpul trebuie să aibă lungimea de {length}",
      max_value: "Câmpul trebuie să fie de cel mult {max}",
      max: "Câmpul nu poate fi mai mare de {length} caractere",
      mimes: "Câmpul trebuie să aibă un tip de fișier valid",
      min_value: "Câmpul trebuie să fie de cel puțin {min}",
      min: "Câmpul trebuie să aibă cel puțin {length} caractere",
      numeric: "Câmpul poate conține numai caractere numerice",
      oneOf: "Câmpul nu este o valoare validă",
      regex: "Formatul câmpului este nevalid",
      required_if: "Câmpul este obligatoriu",
      required: "Câmpul este obligatoriu",
      size: "Mărimea câmpului trebuie să fie mai mică decât {size}KB",
      double: "Câmpul trebuie să fie o zecimală validă",
      strong_password:
        "Parola trebuie să aibă cel puțin 8 caractere și trebuie să conțină cel puțin: 1 majusculă, 1 literă minusculă, 1 cifră și 1 caracter special",
      regular_password:
        "Parola trebuie să aibă cel puțin 8 caractere și trebuie să conțină cel puțin: 1 majusculă, 1 literă minusculă și 1 cifră",
      alpha_num_space:
        "Câmpul poate conține numai caractere alfanumerice și spații",
      not_underage: "Titularul calității de membru trebuie să aibă vârsta de cel puțin {age} ani"
    }
  },
  ru: {
    code: "ru",
    messages: {
      alpha: "Поле может содержать только буквенные символы",
      alpha_num: "Поле может содержать только буквенно-цифровые символы",
      alpha_dash:
        "Поле может содержать буквенно-цифровые символы, а также тире и подчеркивания",
      alpha_spaces:
        "Поле может содержать только буквы алфавита и пробелы",
      between: "Поле должно находиться между {min} и {max}",
      confirmed: "Подтвержденное поле не совпадает",
      credit_card: "Номер кредитной карты недействителен",
      digits: "Поле должно быть числовым и точно содержать {length} цифр",
      dimensions: "Поле должно быть {width} пикселей и {height} пикселей",
      email: "В поле должен быть указан действительный адрес электронной почты",
      excluded: "Поле не является допустимым значением",
      ext: "Поле не является корректным файлом",
      image: "Поле должно быть в формате изображение",
      integer: "Поле должно быть целым числом",
      length: "Поле должно быть  {length} в длину",
      max_value: "Поле должно быть  {length} в длину",
      max: "Поле не должно быть больше {length} символов",
      mimes: "Поле должно иметь допустимый тип файла",
      min_value: "Поле должно быть {min} или больше",
      min: "Поле должно содержать не менее {length} символов",
      numeric: "Поле должно содержать только числовые символы",
      oneOf: "Поле не является допустимым значением",
      regex: "Формат поля недопустим",
      required_if: "Поле обязательно для заполнения",
      required: "Поле обязательно для заполнения",
      size: "Размер поля должен быть меньше {size}KB",
      double: "Поле должно быть действительным десятичным числом",
      strong_password:
        "Пароль должен иметь длину не менее 8 символов и содержать не менее: 1 заглавная буква, 1 строчная буква, 1 цифра и 1 специальный символ",
      regular_password:
        "Пароль должен иметь длину не менее 8 символов и содержать не менее: 1 заглавная буква, 1 строчная буква и 1 цифра",
      alpha_num_space:
        "Поле может содержать только буквенно-цифровые символы и пробелы",
      not_underage: "Участник должен быть старше {age} лет"
    }
  },
  sk: {
    code: "sk",
    messages: {
      alpha: "Pole môže obsahovať iba abecedné znaky",
      alpha_num: "Pole môže obsahovať iba alfanumerické znaky",
      alpha_dash: "Pole môže obsahovať alfanumerické znaky, ako aj pomlčky a podčiarknutia",
      alpha_spaces: "Pole môže obsahovať iba abecedné znaky a medzery",
      between: "Pole musí byť medzi {min} a {max}",
      confirmed: "Potvrdenie poľa sa nezhoduje",
      credit_card: "Číslo kreditnej karty nie je platné",
      digits: "Pole musí byť číselné a obsahovať presne {length} číslic",
      dimensions: "Pole musí byť {width} pixelov na {height} pixelov",
      email: "Pole musí byť platný e-mail",
      excluded: "Pole nemá platnú hodnotu",
      ext: "Pole nie je platný súbor",
      image: "Pole musí byť obrázok",
      integer: "Pole musí byť celé číslo",
      length: "Pole musí mať dĺžku {length}",
      max_value: "Pole musí byť {max} alebo menej",
      max: "Pole nesmie byť dlhšie ako {length} znakov",
      mimes: "Pole musí mať platný typ súboru",
      min_value: "Pole musí byť {min} alebo viac",
      min: "Pole musí mať aspoň {length} znakov",
      numeric: "Pole môže obsahovať iba číselné znaky",
      oneOf: "Pole nemá platnú hodnotu",
      regex: "Formát poľa je neplatný",
      required_if: "Pole je povinné",
      required: "Pole je povinné",
      size: "Veľkosť poľa musí byť menšia ako {size}KB",
      double: "Pole musí byť platné desatinné číslo",
      strong_password: "Heslo musí mať aspoň 8 znakov a musí obsahovať aspoň: 1 veľké písmeno, 1 malé písmeno, 1 číslo a 1 špeciálny znak",
      regular_password: "Heslo musí mať aspoň 8 znakov a musí obsahovať aspoň: 1 veľké písmeno, 1 malé písmeno a 1 číslo",
      alpha_num_space: "Pole môže obsahovať iba alfanumerické znaky a medzery",
      not_underage: "Držiteľ členstva musí mať {age} rokov alebo viac"
    }
  },
  sq: {
    code: "sq",
    messages: {
      alpha: "Fusha mund të përmbajë vetëm karaktere alfabetike",
      alpha_num: "Fusha mund të përmbajë vetëm karaktere alfa-numerike",
      alpha_dash:
        "Fusha mund të përmbajë karaktere alfa-numerike, si dhe viza dhe nënvizime",
      alpha_spaces:
        "Fusha mund të përmbajë vetëm karaktere alfabetike si dhe hapësira",
      between: "Fusha duhet të jetë ndërmjet {min} dhe {max}",
      confirmed: "Konfirmimi në terren nuk përputhet",
      credit_card: "Numri i kartës së kreditit nuk është i vlefshëm",
      digits: "Fusha duhet të jetë numerike dhe saktësisht të përmbajë {length} shifra",
      dimensions: "Fusha duhet të jetë {width} pixel me {height} pixels",
      email: "Fusha duhet të jetë një email i vlefshëm",
      excluded: "Fusha nuk është një vlerë e vlefshme",
      ext: "Fusha nuk është një skedar i vlefshëm",
      image: "Fusha duhet të jetë një imazh",
      integer: "Fusha duhet të jetë një numër i plotë",
      length: "Fusha duhet të jetë {length}",
      max_value: "Fusha duhet të jetë {max} ose më pak",
      max: "Fusha nuk mund të jetë më e madhe se {length} karaktere",
      mimes: "Fusha duhet të ketë një lloj skedari të vlefshëm",
      min_value: "Fusha duhet të jetë {min} ose më shumë",
      min: "Fusha duhet të ketë të paktën {length} karaktere",
      numeric: "Fusha mund të përmbajë vetëm karaktere numerike",
      oneOf: "Fusha nuk është një vlerë e vlefshme",
      regex: "Formati i fushës është i pavlefshëm",
      required_if: "Fusha është e nevojshme",
      required: "Fusha është e nevojshme",
      size: "Madhësia e fushës duhet të jetë më e vogël se {size}KB",
      double: "Fusha duhet të jetë një dhjetore e vlefshme",
      strong_password:
        "Fjalëkalimi duhet të jetë së paku 8 karaktere dhe duhet të përmbajë të paktën: 1 shkronjë të madhe, 1 shkronjë të vogël, 1 numër dhe 1 karakter të veçantë",
      regular_password:
        "Fjalëkalimi duhet të jetë së paku 8 karaktere dhe duhet të përmbajë të paktën: 1 shkronjë të madhe, 1 shkronjë të vogël dhe 1 numër",
      alpha_num_space:
        "Fusha mund të përmbajë vetëm karaktere alfa-numerike dhe hapësirë",
      not_underage: "Mbajtësi i anëtarësimit duhet të jetë {age} vjeç ose më i vjetër"
    }
  },
  sr: {
    code: "sr",
    messages: {
      alpha: "Polje može sadržati samo alfabetne karaktere",
      alpha_num: "Polje može sadržati samo alfanumeričke karaktere",
      alpha_dash: "Polje može sadržati alfanumeričke karaktere, kao i crtice i donje crte",
      alpha_spaces: "Polje može sadržati samo alfabetne karaktere i razmake",
      between: "Polje mora biti između {min} i {max}",
      confirmed: "Potvrda polja se ne poklapa",
      credit_card: "Broj kreditne kartice nije validan",
      digits: "Polje mora biti numeričko i tačno sadržati {length} cifara",
      dimensions: "Polje mora biti {width} piksela po {height} piksela",
      email: "Polje mora biti validna email adresa",
      excluded: "Polje nije validna vrednost",
      ext: "Polje nije validan fajl",
      image: "Polje mora biti slika",
      integer: "Polje mora biti ceo broj",
      length: "Polje mora biti dužine {length}",
      max_value: "Polje mora biti {max} ili manje",
      max: "Polje ne može biti duže od {length} karaktera",
      mimes: "Polje mora biti validan tip fajla",
      min_value: "Polje mora biti {min} ili više",
      min: "Polje mora imati najmanje {length} karaktera",
      numeric: "Polje može sadržati samo numeričke karaktere",
      oneOf: "Polje nije validna vrednost",
      regex: "Format polja je nevažeći",
      required_if: "Polje je obavezno",
      required: "Polje je obavezno",
      size: "Veličina polja mora biti manja od {size}KB",
      double: "Polje mora biti validan decimalni broj",
      strong_password: "Lozinka mora imati najmanje 8 karaktera, i mora sadržati najmanje: 1 veliko slovo, 1 malo slovo, 1 broj i 1 specijalan karakter",
      regular_password: "Lozinka mora imati najmanje 8 karaktera, i mora sadržati najmanje: 1 veliko slovo, 1 malo slovo i 1 broj",
      alpha_num_space: "Polje može sadržati samo alfanumeričke i razmake",
      not_underage: "Vlasnik članstva mora imati {age} godina ili više"
    }
  },
  sv: {
    code: "sv",
    messages: {
      alpha: "Fältet får endast innehålla alfabetiska tecken",
      alpha_num: "Fältet får endast innehålla alfanumeriska tecken",
      alpha_dash: "Fältet får innehålla alfanumeriska tecken samt bindestreck och understreck",
      alpha_spaces: "Fältet får endast innehålla alfabetiska tecken samt mellanslag",
      between: "Fältet måste vara mellan {min} och {max}",
      confirmed: "Fältbekräftelsen matchar inte",
      credit_card: "Kreditkortsnumret är inte giltigt",
      digits: "Fältet måste vara numeriskt och innehålla exakt {length} siffror",
      dimensions: "Fältet måste vara {width} pixlar gånger {height} pixlar",
      email: "Fältet måste vara en giltig e-postadress",
      excluded: "Fältet är inte ett giltigt värde",
      ext: "Fältet är inte en giltig fil",
      image: "Fältet måste vara en bild",
      integer: "Fältet måste vara ett heltal",
      length: "Fältet måste vara {length} långt",
      max_value: "Fältet måste vara {max} eller mindre",
      max: "Fältet får inte vara längre än {length} tecken",
      mimes: "Fältet måste ha en giltig filtyp",
      min_value: "Fältet måste vara {min} eller mer",
      min: "Fältet måste vara minst {length} tecken",
      numeric: "Fältet får endast innehålla numeriska tecken",
      oneOf: "Fältet är inte ett giltigt värde",
      regex: "Fältets format är ogiltigt",
      required_if: "Fältet är obligatoriskt",
      required: "Fältet är obligatoriskt",
      size: "Fältets storlek måste vara mindre än {size}KB",
      double: "Fältet måste vara ett giltigt decimaltal",
      strong_password: "Lösenordet måste vara minst 8 tecken långt, och måste innehålla minst: 1 versal, 1 gemen, 1 siffra och 1 specialtecken",
      regular_password: "Lösenordet måste vara minst 8 tecken långt, och måste innehålla minst: 1 versal, 1 gemen och 1 siffra",
      alpha_num_space: "Fältet får endast innehålla alfanumeriska tecken och mellanslag",
      not_underage: "Medlemmen måste vara {age} år eller äldre"
    }
  },
  uk: {
    code: "uk",
    messages: {
      alpha: "Поле може містити лише алфавітні символи",
      alpha_num: "Поле може містити лише буквено-цифрові символи",
      alpha_dash: "Поле може містити буквено-цифрові символи, а також дефіси та підкреслення",
      alpha_spaces: "Поле може містити лише алфавітні символи та пробіли",
      between: "Поле повинно бути між {min} і {max}",
      confirmed: "Підтвердження поля не співпадає",
      credit_card: "Номер кредитної картки є недійсним",
      digits: "Поле повинно бути числовим і містити рівно {length} цифр",
      dimensions: "Поле повинно бути {width} пікселів на {height} пікселів",
      email: "Поле повинно містити дійсну електронну адресу",
      excluded: "Поле містить недійсне значення",
      ext: "Поле містить недійсний файл",
      image: "Поле повинно бути зображенням",
      integer: "Поле повинно бути цілим числом",
      length: "Поле повинно бути довжиною {length}",
      max_value: "Поле повинно бути {max} або менше",
      max: "Поле не може бути більше {length} символів",
      mimes: "Поле повинно мати дійсний тип файлу",
      min_value: "Поле повинно бути {min} або більше",
      min: "Поле повинно бути принаймні {length} символів",
      numeric: "Поле може містити лише числові символи",
      oneOf: "Поле містить недійсне значення",
      regex: "Формат поля є недійсним",
      required_if: "Поле є обов'язковим",
      required: "Поле є обов'язковим",
      size: "Розмір поля повинен бути менше {size}КБ",
      double: "Поле повинно бути дійсним десятковим числом",
      strong_password: "Пароль повинен містити щонайменше 8 символів, і повинен містити щонайменше: 1 велику літеру, 1 малу літеру, 1 цифру та 1 спеціальний символ",
      regular_password: "Пароль повинен містити щонайменше 8 символів, і повинен містити щонайменше: 1 велику літеру, 1 малу літеру та 1 цифру",
      alpha_num_space: "Поле може містити лише буквено-цифрові символи та пробіли",
      not_underage: "Власник членства повинен бути {age} років або старше"
    }
  }
};

export default locale;
