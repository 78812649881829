const encodeQueryParams = <T>(queryParams: T, options?: { isUrlQuery: boolean }): string => {
  if (queryParams) {
    const queryCharacter = options?.isUrlQuery ? '?' : '';
    const recordToString = Object.keys(queryParams as Record<string, any>).map(
      (key) => `${key}=${queryParams[key as keyof T]}`,
    ).join('&');

    return `${queryCharacter}${recordToString}`;
  }

  return '';
};

const isAnObject = <T>(record: T, options?: { hasProps: boolean }) => {
  const isObject = record && typeof record === 'object';
  const hasLength = options?.hasProps
    && isObject
    && Object.keys(<Record<string, any>>record)?.length;

  return hasLength || isObject;
}

export {
  encodeQueryParams,
  isAnObject,
};

