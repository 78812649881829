import PlansService from '@/commons/services/PlansService';
import { CatalogFilter, FilterQuery, StationMap } from '@/commons/models';
import {
  PlansByFamily,
  CatalogPlan,
  MappedPlan
} from '@/commons/models';

type MapperFunc = (value: MappedPlan, index: number, array: MappedPlan[]) => MappedPlan;

export default class Catalog {
  plans: Array<CatalogPlan>;
  currency: string;
  filteredPlans: Array<MappedPlan>;
  mappedPlans: Array<MappedPlan>;
  catalogFilters: Array<CatalogFilter> = [];
  stations: Array<StationMap>;

  private constructor(
    plans: PlansByFamily,
    mappedPlans: Array<MappedPlan>,
    currency: string,
    stations: Array<StationMap>,
  ) {
    this.plans = Object.values(plans).flat() as CatalogPlan[];
    this.currency = currency;
    this.mappedPlans = mappedPlans;
    this.filteredPlans = [...this.mappedPlans];
    this.stations = stations;
  }

  public static async build(): Promise<Catalog> {
    const { data } = await PlansService.fetchData();
    const { plans, mappedPlans, activeCurrency, stations } = data.content;
    return new Catalog(plans, mappedPlans, activeCurrency, stations);
  }

  public withFilters(catalogFilters: Array<CatalogFilter>): Catalog {
    this.catalogFilters = catalogFilters;
    return this;
  }

  public sortByPrice() {
    this.mappedPlans.forEach(
      (plan) => plan.planItems.sort((a, b) => Number(a.price) - Number(b.price))
    )
  }

  public withMapper(mapper: MapperFunc): Catalog {
    const mappedPlans = this.mappedPlans.map(mapper);
    this.mappedPlans = mappedPlans;
    this.filteredPlans = [...mappedPlans];
    return this;
  }

  public static hasQuery(query: FilterQuery): boolean {
    return Boolean(query
      && Object.keys(query).length > 0
      && Object.values(query).some(Boolean)
      && query.constructor === Object
    );
  }

  private getCatalogFilter(name: string) {
    return this.catalogFilters.find((filter) => {
      return filter.name === name;
    });
  }

  public filter(query: FilterQuery): void {
    if(!Catalog.hasQuery(query)) {
      this.filteredPlans = [...this.mappedPlans];
      return;
    }
    
    let plans = [...this.mappedPlans];
    
    Object.keys(query).forEach((key) => {
      if(!query[key] || !this.getCatalogFilter(key)) {
        return;
      }
      
      const catalogFilter = this.getCatalogFilter(key);
      plans = catalogFilter?.filter(query[key], plans, query) || [];
    });
    
    this.filteredPlans = plans;
  }
}
