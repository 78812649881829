
import Vue, { PropType } from 'vue';
import { v4 as uuidv4 } from 'uuid';

import Accordion from '@/commons/components/molecules/Accordion.vue';

export default Vue.extend({
  name: 'AccordionList',
  components: { Accordion },

  data() {
    return {
      uuid: uuidv4(),
    };
  },

  props: {
    items: {
      type: Array as PropType<Array<{ content: String, title: String }>>,
      required: true,
    },
    multiopen: {
      type: Boolean,
      default: false,
    },
  },
});
