
  import Vue from 'vue';

  export default Vue.extend({
    name: 'FootNotes',
    props: {
      bookingPrice: {
        type: String,
        default: '',
      },
    },
  });
