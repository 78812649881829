import 'cvo-library';
import global from '@/commons/mixins/global';
import header from '@/commons/mixins/header';
import plans from '@/commons/mixins/plans';
import W6Plans from '#/subscriptions/scripts/components/plans/W6Plans.vue';
import createApp from '@/commons/utils/createApp';
import Hero from '@/commons/components/Hero.vue';
import Tabs from '@/commons/components/tabs/Tabs.vue';
import Tab from '@/commons/components/tabs/Tab.vue';
import ActionButton from '@/commons/components/atoms/ActionButton.vue'
import Faqs from '@/commons/components/faqs/Faqs.vue';
import currencyInstance from '@/commons/modules/currency/currencyInstance';
import Footnotes from '@/wizzair/subscriptions/components/Footnotes.vue';

createApp({
  el: '#cvo-vue-library',
  mixins: [global, header, plans],
  components: { W6Plans, Hero, ActionButton, Tab, Tabs, Faqs, Footnotes },
  props: {
    bookingPriceQty: {
      type: Number,
      default: 0
    },
  },

  computed: {
    bookingPrice() {
      return currencyInstance.format(this.bookingPriceQty);
    }
  },
});
