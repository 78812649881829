
import Vue from 'vue';
import accordionBus, { Messages } from '@/commons/buses/accordionBus';
import type { Detail } from '@/commons/utils/Bus';

export default Vue.extend({
  name: 'Accordion',

  props: {
    title: {
      type: String,
      required: true,
    },
    isDefaultOpen: {
      type: Boolean,
      default: false,
    },
    multiopen: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      isOpen: this.isDefaultOpen,
    };
  },

  created() {
    accordionBus.subscribe(Messages.CloseAll, (_: string, detail: Detail) => {
      if (this.group !== detail.group || this.multiopen || !this.isOpen) {
        return;
      }

      this.isOpen = false;
    });
  },

  methods: {
    toggleOpen() {
      if (!this.multiopen) {
        accordionBus.publish(Messages.CloseAll, { group: this.group });
      }
      this.isOpen = !this.isOpen;
    }
  }
});
