export default class Debouncer {
  timeout: ReturnType<typeof setTimeout> | null = null;

  public debounce<T>(func: Function, wait = 0) {
    return(...args: Array<T>) => {
      this.timeout && clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        func.apply(undefined, args);
      }, wait);
    };
  }
}

export function debounce<T>(func: Function, timeout = 300){
  let timer: ReturnType<typeof setTimeout>;
  return (...args: Array<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}