import { i18n } from '@/commons/config/i18n';
import { findAmountByKey } from '@/commons/utils/translations';

export default class Faqs {
  public static generate(baseString: string = 'faqs') {
    const amount = findAmountByKey(baseString, 'title');
    return [...Array(amount).keys()].map((i) => ({
      content: i18n.t(`${baseString}.${i + 1}.content`),
      title: i18n.t(`${baseString}.${i + 1}.title`),
    }));
  }
}
