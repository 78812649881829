export default {
  props: {
    defaultPlanGroup: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedPlanGroup: this.defaultPlanGroup
    };
  }, 
  methods: {
    handlePlansCarousel(planGroupId) {
      this.selectedPlanGroup = planGroupId;

      if (this.selectedPlanGroup === this.defaultPlanGroup) {
        this.$refs.plansCarousel.prevButtonClicked();
        return;
      }

      this.$refs.plansCarousel.nextButtonClicked();
    }
  }
};
