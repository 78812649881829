import { parse, Duration } from 'tinyduration';
import { i18n } from '@/commons/config/i18n';

/**
 * @description Parse ISO duration to string comma separated string
 * @returns {string} Human friendly duration
 */
export function parseDuration(isoDuration: string): string {
  const duration = parse(isoDuration);
  const trans = Object.keys(duration).map((key) => {
    const n = duration[key as keyof Duration];
    return i18n.tc(`duration.${key}`, n as number);
  });
  return trans.join(', ');
}

/**
 * Get current year
 *
 * @returns number
 */
export function getCurrentYear(): number {
  const currentDate = new Date();
  return currentDate.getFullYear();
}

/**
 * Get amount of days in a month by year
 *
 * @returns number
 */
export function getDaysInMonth(month: number, year: number): number {
  return new Date(year, month, 0).getDate();
}

/**
 * Get the amount of full years passed after a given date
 */
export function yearsAgo(date: Date): number {
  const today = new Date(new Date().toDateString());
  let years = today.getFullYear() - date.getFullYear();
  const diffMonths = today.getMonth() - date.getMonth();
  if (
    diffMonths < 0 ||
    (diffMonths === 0 && today.getDate() < date.getDate())
  ) {
    years--;
  }
  return years;
}
