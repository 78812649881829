
import Vue from 'vue';
import AccordionList from '@/commons/components/AccordionList.vue';
import Faqs from '@/commons/modules/Faqs';

export default Vue.extend({
  name: 'Faqs',
  components: { AccordionList },
  props: {
    baseKey: {
      type: String,
      default: 'faqs',
    }
  },
  computed: {
    faqs: function() {
      return Faqs.generate(this.baseKey);
    },
  }
})
