/**
 * Generally tenants require a standard format that is locale independent.
 * Vanilla Javascript requires a locale, and would format money depending on
 * the locale. We could use a third party library, but since the formatting we
 * need is usually quite simple, it's not really worth to add a new dependence
 * just for that.
 */
export default class CurrencyFormatter {
  amount: number;
  decimals?: number;
  decimalsSeparator: string = '.';
  thousandsSeparator: string = '';
  currencySymbol: string = '';
  currencyBeforeValue: boolean = true;

  constructor(amount: number) {
    this.amount = amount;
  }

  public withDecimals(decimals: number) {
    this.decimals = decimals;
    return this;
  }

  public withDecimalSeparator(separator: string) {
    this.decimalsSeparator = separator;
    return this;
  }

  public withThousandsSeparator(separator: string) {
    this.thousandsSeparator = separator;
    return this;

  }
  public withCurrencySymbol(symbol: string) {
    this.currencySymbol = symbol;
    return this;
  }

  public withCurrencyBeforeValue(beforeValue: boolean) {
    this.currencyBeforeValue = beforeValue;
    return this;
  }

  public format() {
    const amountAsString = this.amount.toFixed(this.decimals);
    const defaultDecimalsSeparator = Number(1.1).toFixed(1).charAt(1);
    const [whole, fraction] = amountAsString.split(defaultDecimalsSeparator);
    const wholeFormatted = this.separateThousands(whole).join(
      this.thousandsSeparator
    );
    const numberFormatted = this.addDecimals(wholeFormatted, fraction);

    if (!this.currencySymbol) return numberFormatted;
    
    if (this.currencyBeforeValue) {
      return `${this.currencySymbol} ${numberFormatted}`;
    }

    return `${numberFormatted} ${this.currencySymbol}`;
  }

  private addDecimals(wholeFormatted: string, fraction: string) {
    if (fraction && fraction.length > 0) {
      return [wholeFormatted, fraction].join(this.decimalsSeparator);
    }
    return wholeFormatted;
  }

  private separateThousands(str: string) {
    const groups = this.reverseString(str).match(/.{1,3}/g);
    if (!groups) {
      return [str];
    }
    return groups.map(this.reverseString).reverse();
  }

  private reverseString(str: string) {
    return str.split('').reverse().join('');
  }
}
