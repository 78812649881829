
import Vue from "vue";
import Form from '@/commons/components/form/Form.vue';
import ActionButton from '@/commons/components/atoms/ActionButton.vue';
import Events from "@/commons/constants/events";
 
export default Vue.extend({
  name: 'FilterBar',

  components: {
    Form,
    ActionButton,
  },
  
  props: {
    showSubmit: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  methods: {
    onChange(): void {
      if(!this.showSubmit) {
        this.$refs.submit.$el.click();
      }
    },

    applyFilters(payload: {[field: string]: string}): void {
      /**
       * Event emitted when the form is submitted.
       * It provides the form payload
       * @event submit
       */
      this.$emit(Events.SUBMIT, payload);
    }
  }
});
