
import Vue, { PropType } from 'vue';
import FilterBar from '@/commons/components/filter-bar/FilterBar.vue';
import Autocomplete from '@/commons/components/form/Autocomplete.vue';
import AutocompleteModule from '@/commons/modules/Autocomplete';
import StationsModule from '@/commons/modules/Stations';
import Events from "@/commons/constants/events";
import ActionButton from '@/commons/components/atoms/ActionButton.vue';
import InputField from '@/commons/components/form/InputField.vue';
import Debouncer from '@/commons/utils/Debouncer';
import { 
  StationObject,
  StationObjectMap
} from '@/commons/models';

const STATIONS = window.CVO.stations;
const debouncer = new Debouncer();

export default Vue.extend({
  name: 'StationsFilterBar',

  components: {
    FilterBar,
    Autocomplete,
    ActionButton,
    InputField,
  },

  props: {
    stations: {
      type: Array as PropType<Array<StationObject>>,
      required: false,
      default: () => STATIONS
    }
  },

  computed: {
    stationsMap(): StationObjectMap {
      return StationsModule.getStationsObjectMapByDeparture(this.stations);
    },

    stationsList(): Array<StationObject> {
      return StationsModule.getDepartureStationsObject(this.stations);
    },
  },

  mounted() {
    this.originModule = new AutocompleteModule(this.stationsList);
    this.destinationModule = new AutocompleteModule(this.stationsList);
  },

  data() {
    return {
      isExpanded: false,
      originModule: null,
      destinationModule: null,
      origin: null,
      destination: null,
    }
  },

  methods: {
    toggleBar() {
      this.isExpanded = !this.isExpanded;
    },

    updateList(event: string, target: AutocompleteModule, callback: () => void): void {
      if(!this.origin) {
        this.destination = null;
      }
      
      const suggestions = event ? this.stationsMap[event].arrivalStations : this.stationsList;
      target.setSuggestions(suggestions);

      debouncer.debounce(callback)();
    },

    clearAll() {
      this.origin = null;
      this.destination = null;
    },

    submit(payload: {[field: string]: string}) {
      /**
       * Event emitted when the form is submitted.
       * It provides the form payload
       * @event submit
       */
      this.$emit(Events.SUBMIT, payload);
    },
  }
});
