
import Vue, { VueConstructor } from "vue";
import AutocompleteVue from "@trevoreyre/autocomplete-vue";
import Tooltip from '@/commons/components/tooltip/Tooltip.vue';
import ActionButton from '@/commons/components/atoms/ActionButton.vue';
import formElement from '@/commons/mixins/form/form-element';
import Events from '@/commons/constants/events';
import { AutocompleteData, AutocompleteItem } from '@/commons/models/FormSchema';
import { ValidationProvider } from "vee-validate";

const ExtendedVue = Vue as VueConstructor<
  Vue & InstanceType<typeof formElement>
>;

export default ExtendedVue.extend({
  name: "Autocomplete",

  components: {
    AutocompleteVue,
    Tooltip,
    ActionButton,
  },

  mixins: [formElement],

  model: {
    prop: "value",
    event: Events.CHANGE
  },

  props: {
    /**
     * Placeholder for the input element
     */
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Time in ms for the component to wait before triggering the search event
     */
    debounceTime: {
      type: Number,
      required: false,
      default: 500
    },
    /**
     * Array of items to show as suggestions
     */
    items: {
      type: Array as () => AutocompleteItem[],
      required: false,
      default: () => []
    },
    /**
     * Sets initial value
     */
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    /**
     * Minimum characters needed before triggering the search event.
     * To show the suggestions on first focus use `-1` (not recommended with API suggestions)
     */
    minChars: {
      type: Number,
      required: false,
      default: 2
    },

    /**
     * Whether to show or hide the X button to clear the input and selection
     */
    showClearButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data(): AutocompleteData {
    return {
      selectedItem: null,
      selectedValue: "",
      resolve: null,
      currentValue: this.value,
      showNoResults: false,
      preventSearch: false
    };
  },

  computed: {
    getInputId(): string {
      return this.label.replaceAll(" ", "-");
    },

    isClearButtonVisible(): boolean {
      return this.showClearButton && this.selectedValue !== "";
    }
  },

  watch: {
    /**
     * @description
     * Watch the items property so whenever it
     * changes the promise is resolved and the
     * autocomplete can update the suggestion
     * list
     */
    items(): void {
      // if there's an unresolved promise, items were requested
      // by @search event, otherwise they were provided for init values
      if (this.resolve) {
        this.resolve(this.items);
        this.showNoResults = this.items.length === 0;
      } else {
        this.setValue(this.currentValue as string | number);
      }
    }
  },

  mounted(): void {
    if (this.value) {
      this.setValue(this.value);
    }
  },

  methods: {
    /**
     * @description
     * Invoked on every interaction with the
     * autocomplete component. Due to the anti-pattern
     * implementation of the 3rd party component, it returns a
     * promise and emits an event to let the parent
     * component handle the API call
     */
    handleSearch(value: string): Promise<AutocompleteItem[]> {
      this.showNoResults = false;
      const isLowerThanMinChars = value.length <= this.minChars;
      const isSameAsSelected =
        value === this.selectedValue && this.minChars > -1;
      if (isLowerThanMinChars || isSameAsSelected || this.preventSearch) {
        this.preventSearch = false;
        return Promise.resolve([]);
      }
      return new Promise(resolve => {
        this.resolve = resolve;
        /**
         * Event emitted when typing in the input after the debounce time.
         * It provides the input value `<string>`
         * @event search
         */
        this.$emit(Events.SEARCH, value);
      });
    },

    /**
     * @description
     * Emits an event when an option from the
     * autocomplete suggestion is selected
     */
    handleSelect(item: AutocompleteItem | null): void {
      this.selectedItem = item;
      this.selectedValue = this.getResultValue(item);
      /**
       * Event emitted when selecting a suggestion or clearing the input.
       * It provides the selection item `<AutocompleteItem | null>`
       * @event change
       */
      this.handleValueChange(item?.id);
    },

    handleValueChange(newValue: string | number) {
      if (newValue !== this.currentValue) {
        this.currentValue = newValue;
        this.$emit(Events.CHANGE, newValue || null);
      }
    },

    /**
     * @description
     * Function provided to autocomplete that
     * shows the value of the item
     */
    getResultValue(item: AutocompleteItem | null): string {
      let result;
      if (item) {
        result = item.description
          ? `${item.name} (${item.description})`
          : item.name;
      } else {
        result = "";
      }
      return result as string;
    },

    /**
     * @description
     * Handler for the input blur event.
     * It clears the selection if the content input is removed
     * and keeps the selection if at least 1 char is kept.
     */
    handleBlur(event: Event): void {
      this.setFocus(false);
      this.showNoResults = false;
      const inputEl = event.target as HTMLInputElement;
      if (inputEl.value === "") {
        this.handleSelect(null);
      } else {
        const autocomplete = this.$refs.autocomplete as Vue & {
          value: string | null;
        };
        autocomplete.value = this.selectedValue;
      }
    },

    /**
     * Select items on keydown tab
     * The autocomplete does not provide support for this feature
     * therefore we need this hackish solution.
     * More info:
     * https://github.com/trevoreyre/autocomplete/issues/23
     */
    handleTab() {
      const selectedElement = (this.$refs
        .resultList as HTMLElement)?.querySelector(
        "[aria-selected]"
      ) as HTMLElement;

      const index = selectedElement && selectedElement.dataset.resultIndex;
      const item = index && this.items[parseInt(index)];

      item && this.handleSelect(item);
    },

    /**
     * Add cvo classes to elements in order to keep consistency of element styles
     */
    setInputClasses(): void {
      const autocomplete = this.$refs.autocomplete as Vue & {
        $el: HTMLElement;
      };
      const input = autocomplete.$el.querySelector("input");
      input?.classList.add("Form-field");
      input?.classList.add("input");
      this.label && input?.setAttribute("id", this.getInputId);
    },

    setValue(id: string | number | null): void {
      const item = this.items.find(results => results.id === id) || null;
      if (item) {
        this.handleSelect(item);
      } else {
        this.selectedItem = null;
        this.selectedValue = "";
      }
      // trigger input event so vee validate won't empty the field
      const input = this.$refs.input as HTMLFormElement;
      input.value = this.selectedValue;
      const event = new Event("input", {
        bubbles: true,
        cancelable: true
      });
      input.dispatchEvent(event);
    },

    /**
     * Sets the proper classes
     * @param {object} classes Vee Validate classes https://vee-validate.logaretm.com/v3/guide/state.html#css-classes
     * @returns {object} new object with classes
     */
    setupClasses(classes: object): object {
      const newClasses = {
        ...classes,
        input: true,
        "autocomplete-input": true
      };
      return this.getClasses(newClasses);
    },

    clearSelection(): void {
      this.preventSearch = true;
      this.setValue(null);
      this.handleValueChange(null);
      const validator = this.$refs.validator as InstanceType<
        typeof ValidationProvider
      >;
      validator.validate();
    },

    valueWatcher(newValue: string | number | null) {
      if (newValue === this.currentValue) {
        return;
      }

      if (!newValue) {
        this.clearSelection();
        return;
      }

      this.handleValueChange(newValue);
      this.setValue(newValue);
    }
  }
});
