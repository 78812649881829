import ApiClient from '@/commons/infrastructure/api/apiClient';
import app from '@/commons/modules/app/currentApp';

export default class AppService {
  baseUrl: string;

  constructor() {
    this.baseUrl = `/${app.tenant}/subscriptions/rest-app`;

    if (!this.baseUrl) {
      throw new Error('Missing base URL for Application service')
    }
  }

  async fetchContext() {
    const response = await new ApiClient(this.baseUrl).get('');

    // TODO: error handling;
    return response.data;
  }
}
