import { FilterQuery, FilterableElement } from '@/commons/models';
import BaseFilter from './BaseFilter';
import Stations from '@/commons/modules/Stations';

const DEPARTURE_STATION = 'departureStation';
const ARRIVAL_STATIONS = 'arrivalStations';

export default class StationFilter implements BaseFilter {
  name: string;

  constructor(name: string) {
    this.name = name;
  }

  public filter<T>(value: string, elements: Array<FilterableElement<T>>, query: FilterQuery): Array<FilterableElement<T>> {
    if(this.name === ARRIVAL_STATIONS) {
      return this.filterByArrivalStations(value, elements, query);
    }
    
    return this.filterByDeparture(value, elements);
  }
  
  private filterByDeparture<T>(value: string, elements: Array<FilterableElement<T>>): Array<FilterableElement<T>> {
    return elements.filter((element: FilterableElement<T>) => {
      const stationsMap = Stations.getStationsMapByDeparture(element.stations);
      
      return Boolean(stationsMap[value]);
    });
  }

  private filterByArrivalStations<T>(value: string, elements: Array<FilterableElement<T>>, query: FilterQuery): Array<FilterableElement<T>> {
    if(!query[DEPARTURE_STATION]) {
      return elements.filter((element: FilterableElement<T>) => {
        const stationsList = Stations.getArrivalStationsList(element.stations);
        return stationsList.includes(value);
      });
    }
    
    return elements.filter((element: FilterableElement<T>) => {
      const stationsMap = Stations.getStationsMapByDeparture(element.stations);
      return stationsMap[query.departureStation]?.arrivalStations.includes(value);
    });
  }
}
