
  export default {
    name: 'Tab',

    props: {
      title: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        isActive: false
      }
    },
  }
